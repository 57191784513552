import React, { useEffect, useState } from 'react';

export default function Rating({ block, currentTheme, handleClick, handleMouseOver }) {
	const [rating, setRating] = useState([]);

	useEffect(() => {
		let ratingValue = Number(block?.data?.rating);
		let arr = [];

		if (!isNaN(ratingValue)) {

			if (ratingValue <= 5) {
				for (let i = 1; i <= Math.floor(ratingValue); i++) {
					arr.push(1);
				}

				// If rating is in float
				if (ratingValue % 1 && Math.floor(ratingValue) < 5) {
					arr.push(0);
				}

				// Rest of the empty star if rating is < 5
				for (let i = arr.length; i < 5; i++) {
					arr.push(-1);
				}

				setRating([...arr]);
			} else {
				// If rating is >= 5 
				setRating([1, 1, 1, 1, 1]);
			}
		}
		else {
			setRating([0, 0, 0, 0, 0]);
		}
	}, []);

	return (
		<div id={block?.id}
			className={`${block?.css?.replaceAll(
				"secondary_color",
				`[${currentTheme?.secondary_color}]`
			)
				?.replaceAll(
					"bg_color",
					`[${currentTheme?.bg_color}]`
				)
				?.replaceAll(
					"textColor",
					`[${currentTheme?.textColor}]`
				)
				?.replaceAll(
					"primary_color",
					`[${currentTheme?.primary_color}]`
				)
				?.replaceAll(
					"primary_font_size",
					`[${currentTheme?.primary_font_size}]`
				)
				?.replaceAll(
					"secondary_font_size",
					`[${currentTheme?.secondary_font_size}]`
				)
				?.replaceAll(
					"font_size",
					`[${currentTheme?.font_size}]`
				)}`}

			onClick={(event) => { handleClick(event, `${block.id}`, block) }}
			onMouseOver={(e) => handleMouseOver(e)}

		>

			<div className=''>
				{
					rating?.map((star, i) => (
						<span key={i} className={`mdi ${star == 1 && "mdi-star "} ${star == 0 && "mdi-star-half-full"} ${star == -1 && "mdi-star-outline"}`}></span>

					))
				}

			</div>

			{
				block?.data?.showRatingNumber &&
				<p>{block?.data?.rating % 1 ? block?.data?.rating?.toFixed(1) : block?.data?.rating}</p>
			}
		</div>
	);
}
