import { useState, useEffect, useContext } from 'react'
import { Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import { io } from "socket.io-client";
import { context } from '../../context/Context';
import { dbCall } from '../../common/db';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

// const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);

export default function NotificationModal() {
	const [show, setShow] = useState(false);
	const [message, setMessage] = useState({});
	const [countdown, setCountdown] = useState(0);
	const { user } = useContext(context);

	const params = useParams();
	const {
		setPublish,
		setIsAnimating,
		setNotificationRerender,
		notificationRerender,
		strapiSchema,
		selectedSiteData,
	} = useContext(context)

	const updateSiteIsReady = async (site, key) => {
		dbCall.put('/site/isSiteReady', {
			id: site,
			admin_token: key,
		}).then((response) => {
			console.log('site data : ', response.data);
		});
	}

	const updateBackendSchema = async (data) => {
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < strapiSchema.length; j++) {
				if (data[i] == strapiSchema[i].form_id) {
					strapiSchema.splice(j, 1)
				}
			}
		}
		await dbCall.post("site/update-form-schema", {
			siteId: params.id,
			schema: strapiSchema,
			isStatic: selectedSiteData.static
		})
			.then(response => {
				// console.log(response)
			})
			.catch(err => console.log(err))
	}

	// useEffect(() => {
	// 	if (user == null || user == undefined) {
	// 		return;
	// 	}

	// 	// socket.on("connect", () => {
	// 	// 	socket.emit("join_room", user.email);
	// 	// });

	// 	socket.on("receive_message", async (data) => {
	// 		console.log(data, "data from socket")
	// 		const DATA_JSON = JSON.parse(data.body)

	// 		if (DATA_JSON?.method == "ui") {
	// 			setPublish(false)
	// 			toast.success(DATA_JSON?.message)
	// 			// await updateSiteIsReady(DATA_JSON?.site, DATA_JSON?.accessKey)
	// 		} else if (DATA_JSON?.method == "strapi") {
	// 			// await updateBackendSchema(DATA_JSON?.data)
	// 		} else if (DATA_JSON?.method == "App_Data") {
	// 			toast.success(`Published Successfully`)
	// 			await updateSiteIsReady(DATA_JSON?.siteId)
	// 			const downloadApk = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/deploy/DownloadApk/${DATA_JSON?.siteId}`, {
	// 				headers: {
	// 					'Content-Type': 'application/octet-stream',
	// 					'Content-Disposition': 'attachment; filename=app.apk'
	// 				}
	// 			})
	// 			console.log(downloadApk)
	// 			const blob = new Blob([downloadApk?.data]);

	// 			// const url = window.URL.createObjectURL(blob);
	// 			const url = `${process.env.REACT_APP_BACKEND_URL}/deploy/DownloadApk/${DATA_JSON?.siteId}`;
	// 			const a = document.createElement("a");
	// 			document.body.appendChild(a);
	// 			a.style = "display: none";
	// 			a.href = url;
	// 			a.download = "app.apk";
	// 			a.click();
	// 			// window.URL.revokeObjectURL(url);
	// 		}

	// 		setIsAnimating(false)
	// 		setMessage(data);
	// 		setShow(true);
	// 		setCountdown(15);
	// 		setNotificationRerender(!notificationRerender);
	// 	});
	// }, [user]);

	useEffect(() => {
		let timer;
		if (countdown > 0) {
			timer = setTimeout(() => setCountdown(countdown - 1), 1000);
		}
		return () => clearTimeout(timer);
	}, [countdown]);

	return (
		<div className='font-inter'>
			{
				countdown > 0 &&
				<Transition
					show={show}
					as='div'
					enter=" ease-out duration-300 transition-opacity"
					enterFrom="opacity-0"
					enterTo="opacity-100 "
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					className=' flex justify-center w-full'

				>
					<div className="fixed z-50 bottom-5 w-full max-w-[700px] overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-4 flex items-center">
						<div className="flex-shrink-0">
							<BellIcon className="h-6 w-6 text-gray-400 mt-1" aria-hidden="true" />
						</div>
						<div className="ml-3 w-0 flex-1 pt-0.5 flex items-center justify-between">

							<div>

								<p className="text-sm font-medium text-indigo-600">
									{message.title}
								</p>
								{
									(message.method != "ui" && message.method != "strapi" && message.method != "App_Data") &&
									<p className=" text-sm text-gray-500">
										{message.body}
									</p>
								}
							</div>
							<div className="flex space-x-7">
								<button
									type="button"
									className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none "
									onClick={() => {
										setShow(false)
									}}
								>
									Dismiss
								</button>
							</div>
						</div>

					</div>
				</Transition>

			}

		</div>
	)
}
