import { useContext, useEffect, useState } from "react";
import { useNProgress } from "@tanem/react-nprogress";
import LoadingBar from "react-top-loading-bar";

import { context } from "../context/Context";

const TopLoadingBar = () => {
	const { isAnimating } = useContext(context);
	const [color, setColor] = useState('#f11946');
	const { isFinished, progress } = useNProgress({ isAnimating });

	useEffect(() => {
		if (progress * 100 <= 30) {
			setColor('#f11946')
		} else if (progress * 100 > 30 && progress * 100 < 70) {
			setColor('#FF5F1F');
		} else if (progress * 100 >= 70) {
			setColor('#58e815');
		}
	}, [progress]);

	return <LoadingBar color={color} progress={progress * 100} />
}

export default TopLoadingBar;