import { Listbox, Popover, Switch, Tab } from '@headlessui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from '../../../libraries/utility';
import ColorPicker from "./ColorPicker";
import { dbCall } from '../../../common/db';
import { toast } from 'react-toastify';
import { SketchPicker } from 'react-color';
import { context } from '../../../context/Context';
import removeDuplicateClasses from './removeDuplicateClasses';
import { AddToUndo } from '../../../screens/account/editor';

function Background({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId, classes, addImportant, setClasses, selectedBreakpoint, selectedState }) {

	const tabs = [
		{
			name: 'Color', content: <BgColor setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
				setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
				updateDesign={updateDesign} elementId={elementId} />, icon: <span class="mdi mdi-format-color-fill text-lg text-[#757575]"></span>
		},
		{
			name: 'Gradient', content: <Gradient setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
				setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
				updateDesign={updateDesign} classes={classes} addImportant={addImportant} setClasses={setClasses} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} />, icon: <span class="mdi mdi-gradient-vertical text-lg text-[#757575]"></span>
		},
		{
			name: 'Image', content: <Image setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
				setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
				updateDesign={updateDesign} elementId={elementId} />, icon: <span class="mdi mdi-image text-lg text-[#757575]"></span>
		},
	];


	return (
		<div className="w-full max-w-xs px-2 sm:px-0 divide-y">
			<Tab.Group>
				<Tab.List className="ml-auto flex space-x-1 rounded-xl p-3 w-fit ">
					{tabs.map((tab) => (
						<Tab
							key={tab.name}
							className={({ selected }) =>
								classNames(
									'flex items-center w-full text-sm w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] outline-none',
									'',
									selected
										? 'bg-[#D9D9D9]'
										: 'bg-white'
								)
							}
						>
							{tab.icon}
							{tab.name}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="mt-2">
					{tabs.map((tab) => (
						<Tab.Panel
							key={tab.name}
							className={classNames(
								'rounded-xl bg-white',
							)}
						>
							{tab.content}
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default Background

const BgColor = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) => {

	return (
		<div className="flex items-center justify-between  p-3">
			<div className="font-medium text-gray-900">
				Color
			</div>
			<ColorPicker
				setElementBreakpointSpecificStyleObject={
					setElementBreakpointSpecificStyleObject
				}
				setElementAllBreakpointsStyleObject={
					setElementAllBreakpointsStyleObject
				}
				elementBreakpointSpecificStyleObject={
					elementBreakpointSpecificStyleObject
				}
				updateDesign={updateDesign}
				field={"backgroundColor"}
			/>
		</div>
	)
}




const Gradient = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, classes, addImportant, setClasses, selectedBreakpoint, selectedState }) => {

	const {
		elementId,
		formElementRef,
		elementRef,
		selectedSiteData,
		blocks,
		sqliteSchema,
		strapiSchema,
		undo,
		setUndo,
		setRedo
	} = useContext(context);


	const AllBackground_Position_Gradient = [
		{
			name: "None",
			value: "none"
		},
		{
			name: "To Top",
			value: 'gradient-to-t'
		},
		{
			name: "To Bottom",
			value: "gradient-to-b"
		},
		{
			name: "To Left",
			value: "gradient-to-l"
		},
		{
			name: "To Right",
			value: "gradient-to-r"
		},
		{
			name: "To Top-Right",
			value: "gradient-to-tr"
		},
		{
			name: "To Bottom-Right",
			value: "gradient-to-br"
		},
		{
			name: "To Bottom-Left",
			value: "gradient-to-bl"
		},
		{
			name: "To Top-Left",
			value: "gradient-to-tl"
		}
	]

	const HashMap_BackgroundImage = {
		"none": "None",
		"gradient-to-t": "To Top",
		"gradient-to-b": "To Bottom",
		"gradient-to-l": "To Left",
		"gradient-to-r": "To Right",
		"gradient-to-tr": "To Top-Right",
		"gradient-to-br": "To Bottom-Right",
		"gradient-to-bl": "To Bottom-Left",
		"gradient-to-tl": "To Top-Left"
	}

	const timerRef = useRef();

	return (
		<div className='text-[#373C47]'>

			<div className="flex items-center justify-between text-[#373C47] p-3">
				<div className="text-sm ">
					Background Position
				</div>
				<Listbox
					value={
						(elementBreakpointSpecificStyleObject.backgroundImage.includes('gradient') || elementBreakpointSpecificStyleObject.backgroundImage.includes('none')) ? HashMap_BackgroundImage[String(elementBreakpointSpecificStyleObject.backgroundImage)] : 'none'
					}
					onChange={(value) => {
						updateDesign('backgroundImage', `${value}`)
					}}
				>
					<div className="relative w-full max-w-[130px]">
						<Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
							<span className="capitalize">
								{
									(elementBreakpointSpecificStyleObject.backgroundImage.includes('gradient') || elementBreakpointSpecificStyleObject.backgroundImage.includes('none')) ? HashMap_BackgroundImage[String(elementBreakpointSpecificStyleObject.backgroundImage)] : 'none'
								}
							</span>
							<span className="mdi mdi-chevron-down text-gray-400"></span>
						</Listbox.Button>
						<Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
							{AllBackground_Position_Gradient.map((value, index) => (
								<Listbox.Option
									key={`breakpointmap_${index}`}
									className={({
										active,

									}) =>
										`${active
											? "bg-gray-100"
											: ""
										} cursor-pointer`
									}
									value={value.value}
								>
									{({ selected }) => (
										<>
											<div
												className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
													? " bg-indigo-100 font-medium"
													: ""
													}`}
											>
												{value.name}
											</div>
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</div>
				</Listbox>
			</div>

			<div className="flex items-center justify-end px-3">
				<button
					type="button"
					className={`outline-none rounded text-sm border py-1 px-2 w-max`}
					onClick={() => {
						if (elementBreakpointSpecificStyleObject.viaColor) {
							const arr = ["viaColor", "viaPercent"]
							setElementAllBreakpointsStyleObject((prev) => {
								for (let i = 0; i < arr.length; i++) {
									delete prev[selectedBreakpoint.name][selectedState.key][arr[i]];
								}
								return {
									...prev,
									[selectedBreakpoint.name]: {
										...prev[selectedBreakpoint.name],
										[selectedState.key]: {
											...prev[selectedBreakpoint.name][selectedState.key]
										}
									}
								};
							});

							let updatedClasses = classes;
							for (let i = 0; i < arr.length; i++) {
								updatedClasses = removeDuplicateClasses(
									updatedClasses?.split(" "),
									arr[i],
									selectedBreakpoint.classPrefix,
									selectedState.value
								);
							}
							if (
								formElementRef?.current &&
								formElementRef?.current.id !== elementId.id
							) {
								elementId.data.css[elementRef.current?.dataset?.name] = updatedClasses;
							} else {
								elementId.css = updatedClasses;
							}
							setClasses(updatedClasses);
							AddToUndo(
								selectedSiteData.static,
								blocks,
								sqliteSchema,
								strapiSchema,
								undo,
								setUndo,
								setRedo
							);
						}
						else {
							updateDesign('viaColor', 'rgba(255,255,255,1)');
							updateDesign('viaPercent', '33%');
							updateDesign('fromPercent',"33%");
							updateDesign('toPercent',"33%");
						}
					}}>{elementBreakpointSpecificStyleObject.viaColor ? "Remove Color" : "Add Color"}</button>

			</div>
			<div className="p-3 text-[#373C47]">
				<p className='text-sm mb-1'>From: </p>
				<div className="flex items-center gap-1">
					<Popover className="relative">
						<Popover.Button
							className={`flex items-center bg-[#ffffff] cursor-pointer shadow border rounded p-[3px] border-gray-300 outline-none`}
						>
							<div
								className={`w-[35px] h-[15px] rounded  bg-[${elementBreakpointSpecificStyleObject.fromColor}]`}
							/>

						</Popover.Button>

						<Popover.Panel
							className={'absolute z-[51] top-8 left-0 outline-none'}
						>
							<SketchPicker
								color={elementBreakpointSpecificStyleObject.fromColor}

								onChange={(color) => {
									const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

									setElementBreakpointSpecificStyleObject((prev) => {

										return {
											...prev,
											fromColor: value
										}
									});

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('fromColor', value);

									}, 1000);
								}}

							/>
						</Popover.Panel>
					</Popover>

					<div className="flex items-center w-max ml-auto gap-1">
						<input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
							value={elementBreakpointSpecificStyleObject.fromPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0}

							onChange={(e) => {
								setElementBreakpointSpecificStyleObject((prev) => {

									return {
										...prev,
										fromPercent: e.target.value
									}
								});

								clearTimeout(timerRef.current);
								timerRef.current = setTimeout(() => {
									updateDesign('fromPercent', `${e.target.value}%`);
								}, 1000);
							}} />
						<div className="flex items-center">

							<input
								type="number"
								className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
								value={parseInt(
									elementBreakpointSpecificStyleObject?.fromPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0
								)}
								onChange={(
									e
								) => {

									setElementBreakpointSpecificStyleObject((prev) => {

										return {
											...prev,
											fromPercent: e.target.value
										}
									});

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('fromPercent', `${e.target.value}%`);
									}, 1000);


								}}
								onKeyUp={(
									e
								) => {
									if (
										e.key == "Enter"
									) {
										setElementBreakpointSpecificStyleObject((prev) => {

											return {
												...prev,
												fromPercent: e.target.value
											}
										});

										clearTimeout(timerRef.current);
										timerRef.current = setTimeout(() => {
											updateDesign('fromPercent', `${e.target.value}%`);
										}, 1000);
									}
								}}
								onBlur={(e) => {

									setElementBreakpointSpecificStyleObject((prev) => {

										return {
											...prev,
											fromPercent: e.target.value
										}
									});

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('fromPercent', `${e.target.value}%`);
									}, 1000);
								}}
							/>
							<span className='cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow text-xs'>%</span>
						</div>


					</div>
					<p
						className="mdi mdi-refresh bg-gray-200 border py-[0.4px] px-1 rounded cursor-pointer"
						onClick={() => {
							const arr = ["fromColor", "fromPercent"]
							setElementAllBreakpointsStyleObject((prev) => {
								for (let i = 0; i < arr.length; i++) {
									delete prev[selectedBreakpoint.name][selectedState.key][arr[i]];
								}
								return {
									...prev,
									[selectedBreakpoint.name]: {
										...prev[selectedBreakpoint.name],
										[selectedState.key]: {
											...prev[selectedBreakpoint.name][selectedState.key],
											fromPercent: elementBreakpointSpecificStyleObject.viaPercent ? "33%" : "50%",
											fromColor: "rgba(255,255,255,1)"
										}
									}
								};
							});

							let updatedClasses = classes;
							for (let i = 0; i < arr.length; i++) {
								updatedClasses = removeDuplicateClasses(
									updatedClasses?.split(" "),
									arr[i],
									selectedBreakpoint.classPrefix,
									selectedState.value
								);
							}
							if (
								formElementRef?.current &&
								formElementRef?.current.id !== elementId.id
							) {
								elementId.data.css[elementRef.current?.dataset?.name] = updatedClasses;
							} else {
								elementId.css = updatedClasses;
							}
							setClasses(updatedClasses);
							AddToUndo(
								selectedSiteData.static,
								blocks,
								sqliteSchema,
								strapiSchema,
								undo,
								setUndo,
								setRedo
							);
						}}
					></p>
				</div>
			</div>

			{
				elementBreakpointSpecificStyleObject.viaColor && (

					<div className="p-3 text-[#373C47]">
						<p className='text-sm mb-1'>Via: </p>
						<div className="flex items-center gap-2">
							<Popover className="relative">
								<Popover.Button
									className={`flex items-center bg-[#ffffff] cursor-pointer shadow border rounded p-[3px] border-gray-300 outline-none`}
								>
									<div
										className={`w-[35px] h-[15px] rounded  bg-[${elementBreakpointSpecificStyleObject.viaColor}]`}
									/>

								</Popover.Button>

								<Popover.Panel
									className={'absolute z-[51] top-8 left-0 outline-none'}
								>
									<SketchPicker
										color={elementBreakpointSpecificStyleObject.viaColor}

										onChange={(color) => {
											const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
											setElementBreakpointSpecificStyleObject((prev) => {

												return {
													...prev,
													viaColor: value
												}
											});

											clearTimeout(timerRef.current);
											timerRef.current = setTimeout(() => {
												updateDesign('viaColor', `${value}`);
											}, 1000);
										}}

									/>
								</Popover.Panel>
							</Popover>

							<div className="flex items-center w-max ml-auto gap-1">
								<input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
									value={elementBreakpointSpecificStyleObject.viaPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0}

									onChange={(e) => {
										setElementBreakpointSpecificStyleObject((prev) => {
											return {
												...prev,
												viaPercent: e.target.value
											};
										})

										clearTimeout(timerRef.current);
										timerRef.current = setTimeout(() => {
											updateDesign('viaPercent', `${e.target.value}%`);
										}, 1000);


									}} />
								<div className="flex items-center">

									<input
										type="number"
										className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
										value={parseInt(
											elementBreakpointSpecificStyleObject?.viaPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0
										)}
										onChange={(
											e
										) => {

											setElementBreakpointSpecificStyleObject((prev) => {

												return {
													...prev,
													viaPercent: e.target.value
												}
											});

											clearTimeout(timerRef.current);
											timerRef.current = setTimeout(() => {
												updateDesign('viaPercent', `${e.target.value}%`);
											}, 1000);

										}}
										onKeyUp={(
											e
										) => {
											if (
												e.key == "Enter"
											) {
												setElementBreakpointSpecificStyleObject((prev) => {

													return {
														...prev,
														viaPercent: e.target.value
													}
												});

												clearTimeout(timerRef.current);
												timerRef.current = setTimeout(() => {
													updateDesign('viaPercent', `${e.target.value}%`);
												}, 1000);
											}
										}}
										onBlur={(e) => {

											setElementBreakpointSpecificStyleObject((prev) => {

												return {
													...prev,
													viaPercent: e.target.value
												}
											});

											clearTimeout(timerRef.current);
											timerRef.current = setTimeout(() => {
												updateDesign('viaPercent', `${e.target.value}%`);
											}, 1000);
										}}
									/>
									<span className='cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow text-xs'>%</span>
								</div>
								<p
									className="mdi mdi-refresh bg-gray-200 border py-[0.4px] px-1 rounded cursor-pointer"
									onClick={() => {
										const arr = ["viaColor", "viaPercent"]
										setElementAllBreakpointsStyleObject((prev) => {
											for (let i = 0; i < arr.length; i++) {
												delete prev[selectedBreakpoint.name][selectedState.key][arr[i]];
											}
											return {
												...prev,
												[selectedBreakpoint.name]: {
													...prev[selectedBreakpoint.name],
													[selectedState.key]: {
														...prev[selectedBreakpoint.name][selectedState.key],
														viaPercent: "33%",
														viaColor: "rgba(255,255,255,1)"
													}
												}
											};
										});

										let updatedClasses = classes;
										for (let i = 0; i < arr.length; i++) {
											updatedClasses = removeDuplicateClasses(
												updatedClasses?.split(" "),
												arr[i],
												selectedBreakpoint.classPrefix,
												selectedState.value
											);
										}
										if (
											formElementRef?.current &&
											formElementRef?.current.id !== elementId.id
										) {
											elementId.data.css[elementRef.current?.dataset?.name] = updatedClasses;
										} else {
											elementId.css = updatedClasses;
										}
										setClasses(updatedClasses);
										AddToUndo(
											selectedSiteData.static,
											blocks,
											sqliteSchema,
											strapiSchema,
											undo,
											setUndo,
											setRedo
										);
									}}
								></p>

							</div>
						</div>
					</div>
				)
			}

			<div className="p-3 text-[#373C47]">
				<p className='text-sm mb-1'>To: </p>
				<div className="flex items-center gap-1">
					<Popover className="relative">
						<Popover.Button
							className={`flex items-center bg-[#ffffff] cursor-pointer shadow border rounded p-[3px] border-gray-300 outline-none`}
						>
							<div
								className={`w-[35px] h-[15px] rounded  bg-[${elementBreakpointSpecificStyleObject.toColor}]`}
							/>

						</Popover.Button>

						<Popover.Panel
							className={'absolute z-[51] top-8 left-0 outline-none'}
						>
							<SketchPicker
								color={elementBreakpointSpecificStyleObject.toColor}

								onChange={(color) => {
									const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
									setElementBreakpointSpecificStyleObject((prev) => {

										return {
											...prev,
											toColor: value
										}
									});

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('toColor', `${value}`);
									}, 1000);

								}}

							/>
						</Popover.Panel>
					</Popover>

					<div className="flex items-center w-max ml-auto gap-1">
						<input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
							value={elementBreakpointSpecificStyleObject.toPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0}

							onChange={(e) => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										toPercent: e.target.value
									};
								})

								clearTimeout(timerRef.current);
								timerRef.current = setTimeout(() => {
									updateDesign('toPercent', `${e.target.value}%`);
								}, 1000);


							}} />
						<div className="flex items-center">

							<input
								type="number"
								className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}

								value={elementBreakpointSpecificStyleObject.toPercent?.replace(/[!@#$%^&*a-z]/g, "") || 0}
								onChange={(
									e
								) => {

									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											toPercent: e.target.value
										};
									})

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('toPercent', `${e.target.value}%`);
									}, 1000);

								}}
								onKeyUp={(
									e
								) => {
									if (e.key == "Enter") {
										setElementBreakpointSpecificStyleObject((prev) => {
											return {
												...prev,
												toPercent: e.target.value
											};
										})

										clearTimeout(timerRef.current);
										timerRef.current = setTimeout(() => {
											updateDesign('toPercent', `${e.target.value}%`);
										}, 1000);
									}
								}}
								onBlur={(e) => {
									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											toPercent: e.target.value
										};
									})

									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(() => {
										updateDesign('toPercent', `${e.target.value}%`);
									}, 1000);
								}}
							/>
							<span className='cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow text-xs'>%</span>
						</div>
						<p
							className="mdi mdi-refresh bg-gray-200 border py-[0.4px] px-1 rounded cursor-pointer"
							onClick={() => {
								const arr = ["toColor", "toPercent"]
								setElementAllBreakpointsStyleObject((prev) => {
									for (let i = 0; i < arr.length; i++) {
										delete prev[selectedBreakpoint.name][selectedState.key][arr[i]];
									}
									return {
										...prev,
										[selectedBreakpoint.name]: {
											...prev[selectedBreakpoint.name],
											[selectedState.key]: {
												...prev[selectedBreakpoint.name][selectedState.key],
												toPercent: elementBreakpointSpecificStyleObject.viaPercent ? "33%" : "50%",
												toColor: "rgba(255,255,255,1)"
											}
										}
									};
								});

								let updatedClasses = classes;
								for (let i = 0; i < arr.length; i++) {
									updatedClasses = removeDuplicateClasses(
										updatedClasses?.split(" "),
										arr[i],
										selectedBreakpoint.classPrefix,
										selectedState.value
									);
								}
								if (
									formElementRef?.current &&
									formElementRef?.current.id !== elementId.id
								) {
									elementId.data.css[elementRef.current?.dataset?.name] = updatedClasses;
								} else {
									elementId.css = updatedClasses;
								}
								setClasses(updatedClasses);
								AddToUndo(
									selectedSiteData.static,
									blocks,
									sqliteSchema,
									strapiSchema,
									undo,
									setUndo,
									setRedo
								);
							}}
						></p>

					</div>
				</div>
			</div>


		</div>
	)
}

const Image = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) => {

	const [imageURL, setImageURL] = useState(elementBreakpointSpecificStyleObject?.backgroundImage)
	const timerref = useRef();
	const fileInputRef = useRef(null);

	const { setIsAnimating } = useContext(context);
	
	const handleImageUpload = async (blobInfo) => {
		const filesData = new FormData();
		filesData.append('files', blobInfo)

		const image_url = await dbCall.post(`/qafto/upload-media`, filesData)
			.then(response => {
				let media_cdn_to_upload;
				if (response.data.cdn_url.length > 1) {
					media_cdn_to_upload = response.data.cdn_url;
				} else {
					media_cdn_to_upload = response.data.cdn_url[0];
				}

				setImageURL(media_cdn_to_upload);
				setElementBreakpointSpecificStyleObject((prev) => {
					return {
						...prev,
						'backgroundImage': media_cdn_to_upload
					}
				});

				clearTimeout(timerref.current);
				timerref.current = setTimeout(() => {
					updateDesign('backgroundImage', media_cdn_to_upload);
				}, 1000);
			})
			.catch(error => {
				if (error.response.data?.error?.message) {
					toast.error(error.response.data.error.message);
				} else if (error.response.data.message) {
					toast.error(error.response.data.message);
				} else {
					toast.error('Something went wrong!');
				}
			})

		return image_url;
	};


	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	return (
		<div className=''>
			<div className='flex gap-4 items-end  p-3'>
				<div className={`border w-full h-full flex items-center bg-white rounded-md max-w-[193.88px] h-[107px]`}>
					<div className="flex items-center justify-center space-x-4 w-full">
						<div className="flex-shrink-0">
							<div className="relative">
								{!elementBreakpointSpecificStyleObject?.backgroundImage?.length > 0 || elementBreakpointSpecificStyleObject?.backgroundImage === 'none' ? <span class="mdi mdi-cloud-upload text-7xl text-[#D9D9D9]"></span>
									: <img className='' src={elementBreakpointSpecificStyleObject?.backgroundImage} class="max-w-[193px] max-h-[107px] self-center rounded-md object-cover" alt='upload' />}
								<label htmlFor="file-upload" className="absolute inset-0 cursor-pointer">
									<span className="sr-only">Upload Image</span>
								</label>
								<input
									id="file-upload"
									name="file-upload"
									type="file"
									onChange={(e) => handleImageUpload(e.target.files[0])}
									multiple={false}
									accept="image/*"
									className="sr-only"
									ref={fileInputRef}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='text-xs space-y-1'>
					<button
						type="button"
						onClick={async () => {
							// remove media from aws							
							if (elementBreakpointSpecificStyleObject.backgroundImage.includes(process.env.REACT_APP_USER_CDN)) {

								setIsAnimating(true);

								let media_to_delete = [ elementBreakpointSpecificStyleObject.backgroundImage.substr(process.env.REACT_APP_USER_CDN.length + 1) ];
								// https://usercdn.qafto.com/lq4qj64bs9x6o3f57cg/images/906d3e22a477ba9be4be8921f
								// get lq4qj64bs9x6o3f57cg/images/906d3e22a477ba9be4be8921f only from above link

								// delete media from aws
								await dbCall.post('/qafto/delete-media', {
									media_names: media_to_delete
								}).then((response) => {
									// console.log('delete media response : ', response);
								}).catch((error) => {
									// console.log('delete media error : ', error);
									if (error.response) {
										toast.error(error.response.data.message)
									} else {
										toast.error('Something went wrong!');
									}

								}).finally(() => {
									setIsAnimating(false);
								})
							}

							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundImage': 'none'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundImage', 'none');
							}, 1000);
							setImageURL('')
						}}
						className='rounded-md border border-solid border-zinc-300 px-2 py-1 w-full min-h-[25px]'
					>
						Remove
					</button>
					<button
						type="button"
						onClick={handleButtonClick}
						className='rounded-md bg-[#D9D9D9] border border-solid border-zinc-300  py-1 w-full min-h-[25px]'
					>
						Select Image
					</button>
				</div>
			</div>
			{true && <>
				<div className='text-xs mt-1 px-3'>Upload a jpeg, png, gif or webp</div>
				<div className='text-sm w-full text-center my-1 px-3'>OR</div>
				<div className='flex gap-2 px-3'>
					<span>URL</span>
					<input value={imageURL === 'none' ? '' : imageURL} onChange={(e) => {
						setImageURL(e.target.value)
					}} className='rounded-md border border-solid border-zinc-300 w-full h-[25px]' />
					<button
						type="button"
						onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundImage': imageURL
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundImage', imageURL);
							}, 1000);
						}}
						className='rounded-md border border-solid w-fit bg-zinc-300 px-2 h-[25px]'
					>
						Save
					</button>
				</div>
			</>}
			<div className='divide-y border mt-2'>
				<div className='flex items-center justify-between p-3'>
					<div className="text-[#373C47] font-medium">Format</div>
					<div className=" ml-auto flex space-x-1 rounded-xl w-fit">
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundSize': 'cover'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundSize', 'cover');
								}, 1000);
							}}
							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize === 'cover' ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Full Width
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundSize': 'contain'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundSize', 'contain');
								}, 1000);
							}}
							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize === 'contain' ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Shrink to Fit
						</button>
						<button
							type="button"
							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize?.includes('%') ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Image Size
						</button>
					</div>
				</div>
				{
					elementBreakpointSpecificStyleObject?.backgroundSize?.includes('%') &&
					<div className='p-3'>
						<div className='flex items-center gap-2'>
							<span>Scale</span>
							<input type='range' className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
							<div className='flex items-center'>
								<input
									type="number"
									max={100}
									className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
								/>
								<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
							</div>
						</div>
						<div className='flex items-center justify-between w-full mt-2'>
							<div className='flex items-center gap-2'>
								<span className=''>Repeat X</span>
								<NewSwith />
							</div>
							<div className='flex items-center gap-2'>
								<span className=''>Repeat Y</span>
								<NewSwith />
							</div>
						</div>
						<div className='flex justify-between gap-2 mt-2'>
							<span className=''>Color Tint</span>
							<NewSwith />
						</div>
						{/* Shown when color tint is active */}
						{true && <div className='ml-6 mt-2'>
							<div className='flex items-center justify-between'>
								<span>Color</span>
								<ColorPicker
									setElementBreakpointSpecificStyleObject={
										setElementBreakpointSpecificStyleObject
									}
									setElementAllBreakpointsStyleObject={
										setElementAllBreakpointsStyleObject
									}
									elementBreakpointSpecificStyleObject={
										elementBreakpointSpecificStyleObject
									}
									updateDesign={updateDesign}
									field={"color"}
								/>
							</div>
							<div className='flex items-center gap-2 mt-2'>
								<span>Opacity</span>
								<input type='range' className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
								<div className='flex items-center'>
									<input
										type="number"
										max={100}
										className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
									/>
									<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
								</div>
							</div>
						</div>}
					</div>
				}
				<div className='divide-y'><div className='flex gap-4 p-3'>
					<span>Position</span>
					<div className='grid grid-cols-3 gap-2'>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'left-top'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'left-top');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left-top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-top-left"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'top'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'top');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-collapse-up"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'right-top'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'right-top');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right-top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-top-right"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'left'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'left');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-collapse-left"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'center'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'center');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'center' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-collapse-all"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'right'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'right');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-collapse-right"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'right-bottom'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'right-bottom');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right-bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-bottom-left"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'bottom'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'bottom');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-collapse-down"></span>
						</button>
						<button
							type="button"
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundPosition': 'left-bottom'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundPosition', 'left-bottom');
								}, 1000);
							}}
							className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left-bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}
						>
							<span class="mdi mdi-arrow-bottom-right"></span>
						</button>
					</div>
				</div>
					<div className='flex items-center justify-between mt-3 p-3'>
						<div className='flex items-center justify-center gap-1'>
							Parallax Effects
							<span class="mdi mdi-information-outline"></span>
						</div>
						<NewSwith />
					</div>
				</div>
			</div>
		</div >
	)
}

function NewSwith({ elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject }) {
	const [enabled, setEnabled] = useState(false)

	return (
		<div className="">
			<Switch
				checked={enabled}
				onChange={setEnabled}
				className={`${enabled ? 'bg-indigo-600' : 'bg-[#757575]'}
			relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
			>
				<span className="sr-only">Use setting</span>
				<span
					aria-hidden="true"
					className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
			  pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
				/>
			</Switch>
		</div>
	)
}